import React from "react";
import type Node from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout, Markdown } from "../../components";

type Props = {
    pageContext: Object,
    location: Object
}

export default (props: Props): Node => {
    const data = useStaticQuery(graphql`
        query EngineeringDocs {
            page: markdownRemark(fields: {slug: {eq: "/engineering/overview/home/"}}) {
                id
                frontmatter {
                    title
                    description
                }
                html
                fields {
                    slug
                }
                excerpt(pruneLength: 160)
                html
                headings {
                    depth
                }
            }
            pages: allMarkdownRemark(
                filter: {
                    frontmatter: {
                        templateKey: {eq: "engineering"}
                    }
                },
                sort: {
                    fields: [frontmatter___category, frontmatter___order, frontmatter___date],
                    order: [ASC, ASC, DESC]
                }
            ) {
                edges {
                    next {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            order
                        }
                    }
                    previous {
                        fields {
                            slug
                        }
                        frontmatter {
                            title
                            order
                        }
                    }
                    post: node {
                        id
                        frontmatter {
                            title
                            description
                            category
                            date
                            relativeDate: date(fromNow: true)
                            order
                        }
                        fields {
                            slug
                        }
                        excerpt(pruneLength: 160)
                        html
                    }
                }
            }
            categories: allMarkdownRemark(
                filter: {
                    frontmatter: {
                        templateKey: {eq: "engineering-category"}
                    }
                },
                sort: {
                    fields: [frontmatter___order],
                    order: [ASC]
                }
            ) {
                edges {
                    category: node {
                        id
                        frontmatter {
                            title
                            order
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
            menuLinks: allMarkdownRemark(
                filter: {
                    frontmatter: {
                        templateKey: {eq: "menu-links"}
                    }
                },
                sort: {
                    fields: [frontmatter___order],
                    order: [ASC]
                }
            ) {
                edges {
                    link: node {
                        frontmatter {
                            title
                            path
                            order
                        }
                    }
                }
            }
        }
    `)
    const categories = data.categories.edges.map(item => item.category)
    const pages = data.pages.edges.map(item => ({
        next: item.next, previous: item.previous, page: item.post
    }))
    const menuLinks = data.menuLinks.edges

    const { title, description } = data.page.frontmatter
    const html = data.page.html

    return (
      <Layout
        title={title}
        description={description}
        categories={categories}
        pages={pages}
        menuLinks={menuLinks}
        context={props.pageContext}
        location={props.location}
        showAside={false}
      >
          <Markdown dangerouslySetInnerHTML={{__html: html}} />
      </Layout>
    )
}
